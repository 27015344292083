import React, { useCallback, useState } from 'react'

function downloadBlob(url, filename) {
  // Create a new anchor element
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  a.click()
  return a
}

export default function Exporter({ rawViz, exportProject, chart }) {
  const csv = null;

  const downloadSvg = useCallback(
    (filename) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(svg)
    },
    [rawViz]
    )

  const downloadCsv = useCallback(
    (filename) => {
      var DOMURL = window.URL || window.webkitURL || window
      var file = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      var url = DOMURL.createObjectURL(file)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(file)
    },
    []
    )
    
  const downloadImage = useCallback(
    (format, filename) => {
    var svgString = new XMLSerializer().serializeToString(
      rawViz._node.firstChild
    )
    var DOMURL = window.URL || window.webkitURL || window
    var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
    var url = DOMURL.createObjectURL(svg)
    var canvas = document.createElement('canvas')
    canvas.height = rawViz._node.firstChild.clientHeight
    canvas.width = rawViz._node.firstChild.clientWidth
    var ctx = canvas.getContext('2d')
    var img = new Image()
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      var dataUrl = canvas.toDataURL(format)
      downloadBlob(dataUrl, filename)
      DOMURL.revokeObjectURL(svg)
    }
    img.src = url
  },
  [rawViz]
  )

  const communityShare = () => {
    let message = {
      action : 'share',
      svgString : new XMLSerializer().serializeToString(
        rawViz._node.firstChild
      ),
      csv : settingCsvText(),
      width : rawViz._node.firstChild.clientWidth,
      height : rawViz._node.firstChild.clientHeight,
      chartType : rawViz._chartImplementation?.metadata?.name,
      format : currentFormat,
      fileName : currentFile,
    };
    window.top.postMessage(message, "*"); 
  }
    
  function settingCsvText(){
    const items = rawViz._data;
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(rawViz._dataTypes)
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift(header.join(','))
    return csv.join('\r\n')
  }

  const downloadProject = useCallback(
    filename => {
      const project = exportProject()
      const str = JSON.stringify(project)
      const blob = new Blob([str], { type: 'application/json' })
      const DOMURL = window.URL || window.webkitURL || window
      const url = DOMURL.createObjectURL(blob)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(url)
    },
    [exportProject]
  )

  const exportFormats = ['svg','png','jpg','rawgraphs'];

  const [currentFormat, setCurrentFormat] = useState('svg')
  const [currentFile, setCurrentFile] = useState(chart.metadata.name)

  const downloadViz = useCallback(() => {
    switch (currentFormat) {
      case 'svg':
        downloadSvg(`${currentFile}.svg`)
        break
      case 'csv':
        downloadCsv(`${currentFile}.csv`)
        break
      case 'png':
        downloadImage('image/png', `${currentFile}.png`)
        break
      case 'jpg':
        downloadImage('image/jpeg', `${currentFile}.jpg`)
        break
      case 'rawgraphs':
        downloadProject(`${currentFile}.rawgraphs`)
        break
      default:
        break
    }
  }, [currentFile, currentFormat, downloadCsv, downloadImage, downloadProject, downloadSvg])
  
  const handleCurrnetFormat = useCallback(
    (format) => {
      setCurrentFormat(format);
      setDropClass('');
    }
  ,[])
  const [dropClass, setDropClass] = useState('')
  return (
    <div className="setting">
      <div className="left">
        <div className="download-inner">
          <input
            type="text"
            value={currentFile}
            onChange={(e) => setCurrentFile(e.target.value)}
          ></input>
          <div className="dropdown-wrap">
            <div className={['dropdown', dropClass].join(' ')} onClick={() => {
              dropClass ? setDropClass('') : setDropClass('on')
            }}>
              {`.${currentFormat}`}
              <i className="down"></i>
            </div>
            <div className={['dropdown-menu', dropClass].join(' ')}>
              {exportFormats.map(
                (d) => {
                  return (
                    <div key={d} onClick={() => handleCurrnetFormat(d)}>
                      {d}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
        <button className="blue btnNew down w-200" onClick={downloadViz}>
          Download
        </button>
      </div>
      <div id="shareBtn" className="line btnNew share w-200" style={{cursor:'pointer'}} onClick={communityShare}><span>커뮤니티 공유</span></div>
    </div>
  )
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InlineColorPicker_swatch__11WDP {\n  padding: 4px 8px;\n  background: white;\n  border: none;\n  border-bottom: 1px solid var(--light);\n  border-radius: 4px;\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  font-size: 0.8461538462rem; }\n\n.InlineColorPicker_color__12j-E {\n  width: 16px;\n  height: 16px;\n  border: 1px solid #ddd;\n  margin-right: 8px; }\n\n.InlineColorPicker_popover__3Hchl {\n  position: absolute;\n  z-index: 2000; }\n\n.InlineColorPicker_cover__2KTIs {\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px; }\n", ""]);
// Exports
exports.locals = {
	"swatch": "InlineColorPicker_swatch__11WDP",
	"color": "InlineColorPicker_color__12j-E",
	"popover": "InlineColorPicker_popover__3Hchl",
	"cover": "InlineColorPicker_cover__2KTIs"
};
module.exports = exports;

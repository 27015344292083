import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import {
  BsClipboard, BsGift, BsUpload
} from 'react-icons/bs'
import { DATA_LOADER_MODE } from '../../hooks/useDataLoader'
import DataGrid from '../DataGrid1/DataGrid'
import DataSamples from '../DataSamples1/DataSamples'
import JsonViewer from '../JsonViewer'
import ParsingOptions from '../ParsingOptions1'
import WarningMessage from '../WarningMessage'
import DataMismatchModal from './DataMismatchModal'
import Paste from './loaders/Paste'
import UploadFile from './loaders/UploadFile'
import Loading from './loading'

function DataLoader({
  userInput,
  setUserInput,
  userData,
  userDataType,
  parseError,
  unstackedColumns,
  separator,
  setSeparator,
  thousandsSeparator,
  setThousandsSeparator,
  decimalsSeparator,
  setDecimalsSeparator,
  locale,
  setLocale,
  stackDimension,
  dataSource,
  data,
  loading,
  coerceTypes,
  loadSample,
  handleInlineEdit,
  handleStackOperation,
  setJsonData,
  resetDataLoader,
  dataLoaderMode,
  startDataReplace,
  cancelDataReplace,
  commitDataReplace,
  replaceRequiresConfirmation,
  hydrateFromProject,
  setStepCnt,
  stepCnt
}) {
  const [loadingError, setLoadingError] = useState()
  const options = [
    {
      id: 'paste',
      name: '데이터 붙여넣기',
      loader: (
        <Paste
          userInput={userInput}
          setUserInput={(rawInput) => setUserInput(rawInput, { type: 'paste' })}
          setLoadingError={setLoadingError}
        />
      ),
      message:
        '다른 애플리케이션이나 웹사이트에서 데이터를 복사하여 붙여 넣습니다. 테이블 형식(TSV, CSV, DSV) 또는 JSON 데이터를 사용할 수 있습니다.',
      icon: BsClipboard,
      allowedForReplace: true,
    },
    {
      id: 'upload',
      name: '데이터 업로드',
      loader: (
        <UploadFile
          userInput={userInput}
          setUserInput={(rawInput) => setUserInput(rawInput, { type: 'file' })}
          setLoadingError={setLoadingError}
        />
      ),
      message:
        '테이블 형식(TSV, CSV, DSV) 또는 JSON 데이터를 로드할 수 있습니다.',
      icon: BsUpload,
      allowedForReplace: true,
    },
    {
      id: 'samples',
      name: '데이터 샘플 사용해보기',
      message: '',
      loader: (
        <DataSamples
          onSampleReady={loadSample}
          setLoadingError={setLoadingError}
        />
      ),
      icon: BsGift,
      allowedForReplace: true,
    },
  ]
  const [optionIndex, setOptionIndex] = useState(0)
  const selectedOption = options[optionIndex]

  let mainContent
  if (stepCnt === '1') {
    mainContent = (
      <div className="on">
        {selectedOption.loader}
        {selectedOption.message && (
          <p className="noti">
            <span>{selectedOption.message}</span>
          </p>
        )}
      </div>
    )
  } else {
    if (userData && data) {
      mainContent = (
        <DataGrid
          userDataset={userData}
          dataset={data.dataset}
          errors={data.errors}
          dataTypes={data.dataTypes}
          coerceTypes={coerceTypes}
          onDataUpdate={handleInlineEdit}
        />
      )
    } else if (userDataType === 'json' && userData === null) {
      mainContent = (
        <JsonViewer
          context={JSON.parse(userInput)}
          selectFilter={(ctx) => Array.isArray(ctx)}
          onSelect={(ctx, path) => {
            setJsonData(ctx, path)
          }}
        />
      )
    } else if (loading && !data) {
      mainContent = <Loading />
    } else {
      mainContent = (
        <div className="on">
          {selectedOption.loader}
          {selectedOption.message && (
            <p className="noti">
              <span>{selectedOption.message}</span>
            </p>
          )}
        </div>
      )
    }
  }
  

  React.useEffect(() => {
    if (userData) {
      setStepCnt('2');
    }
  }, [setStepCnt, userData])

  // #TODO: memoize/move to component?
  function parsingErrors(data) {
    const errors = get(data, 'errors', [])
    const successRows = data.dataset.length - errors.length
    const row = errors[0].row + 1
    const column = Object.keys(errors[0].error)[0]
    return (
      <span>
        Ops, please check <span className="font-weight-bold">row {row}</span> at
        column <span className="font-weight-bold">{column}</span>.{' '}
        {errors.length === 2 && (
          <>
            {' '}
            There's another issue at row{' '}
            <span className="font-weight-bold">{errors[1].row + 1}</span>.{' '}
          </>
        )}
        {errors.length > 2 && (
          <>
            {' '}
            There are issues in{' '}
            <span className="font-weight-bold">{errors.length - 1}</span> more
            rows.{' '}
          </>
        )}
        {successRows > 0 && (
          <>
            The remaining{' '}
            <span className="font-weight-bold">
              {successRows} row{successRows > 1 && <>s</>}
            </span>{' '}
            look{successRows === 1 && <>s</>} fine.
          </>
        )}
      </span>
    )
  }

  const reloadRAW = useCallback(() => {
    window.location.replace(window.location.pathname)
  }, [])

  const data1Cls = stepCnt === '1' ? "data1" : "";  return (
    <>
      {(stepCnt <= '2') && <div className={["data-content-wrap", data1Cls].join(' ')}>
        <div data-val={stepCnt}></div>
        {stepCnt === '1' && (
          <ul className="data-nav">
            {options
              .filter((opt) => {
                return (
                  dataLoaderMode !== DATA_LOADER_MODE.REPLACE ||
                  opt.allowedForReplace
                )
              })
              .map((d, i) => {
                const classnames = [
                  d.id === selectedOption.id ? 'on' : null,
                ]
                  .filter((c) => c !== null)
                  .join(' ')
                return (
                  <li
                    key={d.id}
                    className={classnames}
                    onClick={() => setOptionIndex(i)}
                  >
                    <span>{d.name}</span>
                  </li>
                )
              })}

            {dataLoaderMode === DATA_LOADER_MODE.REPLACE && (
              <div className="btnNew-wrap border">
                <div 
                  className="btnNew grey reset white"
                  onClick={reloadRAW}
                >
                  <span>{"초기화"}</span>
                </div>
                <div 
                  className="btnNew grey cancel"
                  onClick={() => {cancelDataReplace()
                  }}
                >
                  <span>{"취소"}</span>
                </div>
              </div>
            )}
          </ul>
        )}
        {userData && stepCnt === '2' && (
          <div className="data-nav">
            <ParsingOptions
              locale={locale}
              setLocale={setLocale}
              separator={separator}
              setSeparator={setSeparator}
              thousandsSeparator={thousandsSeparator}
              setThousandsSeparator={setThousandsSeparator}
              decimalsSeparator={decimalsSeparator}
              setDecimalsSeparator={setDecimalsSeparator}
              dimensions={data ? unstackedColumns || data.dataTypes : []}
              stackDimension={stackDimension}
              setStackDimension={handleStackOperation}
              userDataType={userDataType}
              dataSource={dataSource}
              onDataRefreshed={(rawInput) => setUserInput(rawInput, dataSource)}
            />

            <div className="btnNew-wrap border">
              <div 
                className="btnNew grey reset white"
                onClick={reloadRAW}
              >
                <span>{"초기화"}</span>
              </div>
              <div 
                className="btnNew grey change"
                onClick={() => {
                  setStepCnt('1')
                  setOptionIndex(0)
                  startDataReplace()
                }}
              >
                <span>{"데이터 변경"}</span>
              </div>
            </div>
          </div>
        )}
        <div className="data-content">
          {mainContent}

          {data && stepCnt === '2' && !parseError && get(data, 'errors', []).length === 0 && (
            <p className="noti">
              <span>{data.dataset.length}개의 행({data.dataset.length * Object.keys(data.dataTypes).length}{' '}
                  개의 셀)이 성공적으로 구문 분석되었습니다. 이제 차트를 선택할 수 있습니다.</span>
            </p>
          )}

          {parseError && (
            <WarningMessage variant="danger" message={parseError} />
          )}

          {get(data, 'errors', []).length > 0 && (
            <WarningMessage variant="warning" message={parsingErrors(data)} />
          )}

          {loadingError && (
            <WarningMessage variant="danger" message={loadingError} />
          )}
        </div>
      </div>}
      {replaceRequiresConfirmation && (
        <DataMismatchModal
          replaceRequiresConfirmation={replaceRequiresConfirmation}
          commitDataReplace={commitDataReplace}
          cancelDataReplace={cancelDataReplace}
        />
      )}
    </>
  )
}

export default React.memo(DataLoader)

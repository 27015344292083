import React from 'react';

const ChartOptionBoolean = ({ optionId, label, value, error, onChange, className, isEnabled }) => {
  return (
    <div className={className}>
      {label}
      <label className="switch">
        <p>{value ? 'Yes' : 'No'}</p>
        <input type="checkbox" id={optionId} value={!!value}/>
        <span className="slider round" onClick={()=> {
          onChange(!value);
        }}></span>
      </label>
      {error && (
        <div className="col-12">
          <small>
            <i>{error}</i>
          </small>
        </div>
      )}
    </div>
  )
}

export default React.memo(ChartOptionBoolean)

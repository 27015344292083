import React, { useCallback, useState } from 'react'
import { separatorsLabels } from '../../constants'


export default function SeparatorSelector({
  title,
  value,
  onChange,
  ...props
}) {
  const inputValue = value
    .replace(/\r/g, '\\r')
    .replace(/\n/g, '\\n')
    .replace(/\t/g, '\\t')

  const [dropClass, setDropClass] = useState('')
  const handleChange = useCallback(
    (separator) => {
      if (onChange) {
        const nextValue = separator
          .replace(/\\r/g, '\r')
          .replace(/\\n/g, '\n')
          .replace(/\\t/g, '\t')
        onChange(nextValue)
      }
      setDropClass('')
    },
    [onChange]
  )

  const formatValue = (value) => {
    return <>{separatorsLabels[value]}</>
  }

  return (
    <div className="option">
      {title}
      <div className="dropdown-wrap">
        <div
          className={['dropdown', dropClass].join(' ')}
          onClick={() => {
            dropClass ? setDropClass('') : setDropClass('on')
          }}
        >
          {formatValue(inputValue)}
          <i className="down"></i>
        </div>
        <div className={['dropdown-menu', dropClass].join(' ')}>
          {Object.keys(separatorsLabels).map((key) => {
            return (
              <div key={key} onClick={() => handleChange(key)}>{formatValue(key)}</div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import { localeList } from '../../constants'

export default function DateLocaleSelector({
  title,
  value,
  onChange,
  ...props
}) {
  const handleChange = useCallback(
    (locale) => {
      if (onChange) {
        const nextLocale = locale
        onChange(nextLocale)
      }
      setDropClass('')
    },
    [onChange]
  )
  const [dropClass, setDropClass] = useState('')
  return (
    <div className="option">
      {title}
      <div className="dropdown-wrap">
        <div
          className={['dropdown', dropClass].join(' ')}
          onClick={() => {
            dropClass ? setDropClass('') : setDropClass('on')
          }}
        >
          {value}
          <i className="down"></i>
        </div>
        <div className={['dropdown-menu', dropClass].join(' ')}>
          {Object.keys(localeList).map((key) => {
            return (
              <div key={key} onClick={() => handleChange(key)}>
                {key}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import S from './UploadFile.module.scss'

export default function UploadFile({ setUserInput, setLoadingError }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader()
      reader.addEventListener('load', (e) => {
        setUserInput(e.target.result)
        setLoadingError(null)
      })
      if (acceptedFiles.length) {
        reader.readAsText(acceptedFiles[0], 'euc-kr')
      }
    },
    [setLoadingError, setUserInput]
  )
  const { getRootProps, getInputProps, isDragReject, isDragAccept } =
    useDropzone({
      onDrop,
      accept:
        'text/csv,text/plain,application/json,application/vnd.ms-excel,text/tsv,text/tab-separated-values',
      maxFiles: 1,
    })
  return (
    <div
      className={[
        classNames(S.dropzone, {
          [S.reject]: isDragReject,
          [S.accept]: isDragAccept,
        }),
        'data-upload',
      ].join(' ')}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <span>여기로 파일을 드래그하거나 PC의 파일을 첨부할 수 있습니다.</span>
      <label htmlFor="data-upload">파일 찾기</label>
      <input className={S['browse-button']}></input>
      {isDragAccept && <p>All files will be accepted</p>}
      {isDragReject && <p>Some files will be rejected</p>}
    </div>
  )
}

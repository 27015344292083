import React from 'react'
import InilineColorPicker from '../../InlineColorPicker1'
import ChartOptionSelect from './ChartOptionSelect'

const ChartOptionColor = ({
  value,
  error,
  onChange,
  default: defaultValue,
  label,
  isEnabled,
  ...props
}) => {
  if (props.options) {
    return (
      <ChartOptionSelect
        value={value}
        error={error}
        onChange={onChange}
        default={defaultValue}
        label={label}
        {...props}
      />
    )
  }

  return (
    <div className={'option'}>
      {label}
      <InilineColorPicker disabled={!isEnabled} color={value} onChange={onChange} />
      {error && (
        <small>
          <i>{error}</i>
        </small>
      )}
    </div>
  )
}

export default React.memo(ChartOptionColor)

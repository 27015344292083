import React from 'react'

export default function Paste({ userInput, setUserInput, setLoadingError }) {
  return (
    <textarea
      value={userInput ? userInput : ''}
      onChange={(e) => {
        const str = e.target.value
        setUserInput(str)
        setLoadingError(null)
      }}
      placeholder={'내용을 입력하세요.'}
    />
  )
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SparqlFetch_url-input__1OTEr {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px; }\n\n.SparqlFetch_query-input-here__1GTNa {\n  padding: 12px 0; }\n\n.SparqlFetch_base-iri-input-here__1MbOa {\n  padding: 0 0 12px 0; }\n\nsparql-editor {\n  height: 349px; }\n", ""]);
// Exports
exports.locals = {
	"url-input": "SparqlFetch_url-input__1OTEr",
	"query-input-here": "SparqlFetch_query-input-here__1GTNa",
	"base-iri-input-here": "SparqlFetch_base-iri-input-here__1MbOa"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParsingOptions_parsingOptions__3Rtj8 b {\n  font-weight: 500; }\n\n.ParsingOptions_parsingOptions__3Rtj8 input {\n  width: 40px;\n  height: 40px;\n  margin-left: 8px; }\n\n.ParsingOptions_parsingOptions__3Rtj8 .dropdown {\n  margin-left: 8px; }\n  .ParsingOptions_parsingOptions__3Rtj8 .dropdown button {\n    padding-right: 24px;\n    height: 40px;\n    width: 108px; }\n\n.ParsingOptions_parsingOptions__3Rtj8 .option {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 16px 0; }\n\n.ParsingOptions_parsingOptions__3Rtj8 .ParsingOptions_refresh-button__1S75V {\n  width: 100%;\n  height: 32px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center; }\n\n.ParsingOptions_separator-preview__2CxwK {\n  font-family: var(--font-family-monospace);\n  background-color: var(--gray-200);\n  color: var(--gray-900);\n  width: 16px;\n  height: 16px;\n  display: inline-block;\n  text-align: center;\n  border-radius: 4px;\n  margin-right: 4px; }\n", ""]);
// Exports
exports.locals = {
	"parsingOptions": "ParsingOptions_parsingOptions__3Rtj8",
	"refresh-button": "ParsingOptions_refresh-button__1S75V",
	"separator-preview": "ParsingOptions_separator-preview__2CxwK"
};
module.exports = exports;

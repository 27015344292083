import { chart as rawChart } from '@rawgraphs/rawgraphs-core'
import React, { useEffect, useState } from 'react'
import { WEBWORKER_ACTIVE } from '../../constants'
import { mapDataInWorker } from '../../worker'
import ChartOptions from '../ChartOptions1'
import ChartPreview from '../ChartPreview1'

const ChartPreviewWithOptions = ({
  chart,
  dataset,
  dataTypes,
  mapping,
  visualOptions,
  setVisualOptions,
  setRawViz,
  setMappingLoading,
  rawViz,
  exportProject,
  setStepCnt
}) => {
  const [error, setError] = useState({variant: "secondary", message: "Required chart variables"})
  const [mappedData, setMappedData] = useState(null)
  const [divHeight, setDivHeight] = useState("810")

  useEffect(() => {
    // console.info('Updating mapped dataset')
    try {
      setMappingLoading(true)

      if (WEBWORKER_ACTIVE) {
        mapDataInWorker(chart.metadata.name, {
          data: dataset,
          mapping: mapping,
          dataTypes,
        })
          .then((mappedData) => {
            setMappingLoading(false)
            setMappedData(mappedData)
          })
          .catch((err) => {
            console.error(err)
            setMappingLoading(false)
            setMappedData(null)
          })
      } else {
        const viz = rawChart(chart, {
          data: dataset,
          mapping: mapping,
          dataTypes,
        })
        const vizData = viz._getVizData()
        setMappingLoading(false)
        setMappedData(vizData)
      }
    } catch (e) {
      console.error(e)
      setMappingLoading(false)
      setMappedData(null)
    }
  }, [
    chart,
    mapping,
    dataTypes,
    setError,
    setRawViz,
    setMappingLoading,
    dataset,
  ])

  return (
    <div className="data-content-wrap data4" style={{minHeight: divHeight + "px"}}>
      <ChartOptions
        chart={chart}
        dataset={dataset}
        mapping={mapping}
        dataTypes={dataTypes}
        visualOptions={visualOptions}
        setVisualOptions={setVisualOptions}
        error={error}
        mappedData={mappedData}
        setStepCnt={setStepCnt}
      />
      <ChartPreview
        chart={chart}
        dataset={dataset}
        dataTypes={dataTypes}
        mapping={mapping}
        visualOptions={visualOptions}
        error={error}
        setError={setError}
        setRawViz={setRawViz}
        mappedData={mappedData}
        rawViz={rawViz}
        exportProject={exportProject}
        setDivHeight={setDivHeight}
      />
    </div>
  )
}

export default ChartPreviewWithOptions

import { get } from 'lodash'
import React from 'react'
import { Button } from 'react-bootstrap'
import { BsArrowRepeat } from 'react-icons/bs'
import { fetchData as fetchDataFromSparql } from '../DataLoader/loaders/SparqlFetch'
import { fetchData as fetchDataFromUrl } from '../DataLoader/loaders/UrlFetch'
import DateLocaleSelector from './DateLocaleSelector'
import DecimalsSeparatorSelector from './DecimalsSeparatorSelector'
import styles from './ParsingOptions.module.scss'
import SeparatorSelector from './SeparatorSelector'
import StackSelector from './StackSelector'
import ThousandsSeparatorSelector from './ThousandsSeparatorSelector'


const dataRefreshWorkers = {
  url: fetchDataFromUrl,
  sparql: fetchDataFromSparql,
}

const dataRefreshCaptions = {
  url: 'Refresh data from url',
  sparql: 'Refresh data from query',
}

export default function ParsingOptions(props) {
  const refreshData = async () => {
    const dataRefreshImpl =
      dataRefreshWorkers[get(props.dataSource, 'type', '')]
    const data = await dataRefreshImpl(props.dataSource)
    props.onDataRefreshed(data)
  }

  return (
    <>
      <p className="title">데이터 로드</p>
      <div className="option-wrap">
        <p className="title">데이터 파싱 옵션</p>
        {props.userDataType === 'csv' && (
          <SeparatorSelector
            title="열 구분자"
            value={props.separator}
            onChange={(nextSeparator) => props.setSeparator(nextSeparator)}
          />
        )}
        <ThousandsSeparatorSelector
          title="천 단위 구분 기호"
          value={props.thousandsSeparator}
          onChange={(nextSeparator) =>
            props.setThousandsSeparator(nextSeparator)
          }
        />
        <DecimalsSeparatorSelector
          title="소수점 기분 기호"
          value={props.decimalsSeparator}
          onChange={(nextSeparator) =>
            props.setDecimalsSeparator(nextSeparator)
          }
        />

        <DateLocaleSelector
          title="날짜 로케일"
          value={props.locale}
          onChange={(nextLocale) => props.setLocale(nextLocale)}
        />

        {get(dataRefreshWorkers, get(props.dataSource, 'type', ''), null) && (
          <Button
            color="primary"
            className={styles['refresh-button']}
            onClick={() => refreshData()}
          >
            <BsArrowRepeat className="mr-2" />
            {get(
              dataRefreshCaptions,
              get(props.dataSource, 'type', ''),
              'Refresh data'
            )}
          </Button>
        )}
      </div>

      <div className="option-wrap">
        <p className="title">데이터 변환</p>
        <StackSelector
          title="스택 온"
          value={props.stackDimension}
          list={props.dimensions}
          onChange={(nextStackDimension) =>
            props.setStackDimension(nextStackDimension)
          }
        />
      </div>
    </>
  )
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UrlFetch_url-input__3vKK2 {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px; }\n", ""]);
// Exports
exports.locals = {
	"url-input": "UrlFetch_url-input__3vKK2"
};
module.exports = exports;

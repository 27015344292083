import {
  getDefaultOptionsValues, getOptionsConfig, serializeProject
} from '@rawgraphs/rawgraphs-core'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import charts from './charts'
import ChartPreviewWithOptions from './components/ChartPreviewWIthOptions1'
import ChartSelector from './components/ChartSelector1'
import DataLoader from './components/DataLoader1'
import DataMapping from './components/DataMapping1'
import useDataLoader from './hooks/useDataLoader'
import usePrevious from './hooks/usePrevious'
import './styles/layout.css'
import './styles/respon.css'
// import './styles/feCommon.min.css'
import './styles/sub.css'

// #TODO: i18n

function App(props) {
  const dataLoader = useDataLoader()
  const {
    userInput,
    userData,
    userDataType,
    parseError,
    unstackedData,
    unstackedColumns,
    data,
    separator,
    thousandsSeparator,
    decimalsSeparator,
    locale,
    stackDimension,
    dataSource,
    loading,
    hydrateFromSavedProject,
  } = dataLoader

  /* From here on, we deal with viz state */
  const [currentChart, setCurrentChart] = useState(null)
  const [mapping, setMapping] = useState({})
  const [visualOptions, setVisualOptions] = useState({})
  const [rawViz, setRawViz] = useState(null)
  const [mappingLoading, setMappingLoading] = useState(false)
  const dataMappingRef = useRef(null)
  const [stepCnt , setStepCnt] = useState('1');

  const columnNames = useMemo(() => {
    if (get(data, 'dataTypes')) {
      return Object.keys(data.dataTypes)
    }
  }, [data])

  const prevColumnNames = usePrevious(columnNames)
  const clearLocalMapping = useCallback(() => {
    if (dataMappingRef.current) {
      dataMappingRef.current.clearLocalMapping()
    }
  }, [])

  //resetting mapping when column names changes (ex: separator change in parsing)
  useEffect(() => {
    if (prevColumnNames) {
      if (!columnNames) {
        setMapping({})
        clearLocalMapping()
      } else {
        const prevCols = prevColumnNames.join('.')
        const currentCols = columnNames.join('.')
        if (prevCols !== currentCols) {
          setMapping({})
          clearLocalMapping()
        }
      }
    }
  }, [columnNames, prevColumnNames, clearLocalMapping])

  const handleChartChange = useCallback(
    (nextChart) => {
      setMapping({})
      clearLocalMapping()
      setCurrentChart(nextChart)
      const options = getOptionsConfig(nextChart?.visualOptions)
      setVisualOptions(getDefaultOptionsValues(options))
      setRawViz(null)
    },
    [clearLocalMapping]
  )

  const exportProject = useCallback(() => {
    return serializeProject({
      userInput,
      userData,
      userDataType,
      parseError,
      unstackedData,
      unstackedColumns,
      data,
      separator,
      thousandsSeparator,
      decimalsSeparator,
      locale,
      stackDimension,
      dataSource,
      currentChart,
      mapping,
      visualOptions,
    })
  }, [
    currentChart,
    data,
    dataSource,
    decimalsSeparator,
    locale,
    mapping,
    parseError,
    separator,
    stackDimension,
    thousandsSeparator,
    userData,
    userDataType,
    userInput,
    visualOptions,
    unstackedColumns,
    unstackedData,
  ])

  // project import
  const importProject = useCallback(
    (project) => {
      hydrateFromSavedProject(project)
      setCurrentChart(project.currentChart)
      setMapping(project.mapping)
      // adding "annotations" for color scale:
      // we annotate the incoming options values (complex ones such as color scales)
      // to le the ui know they are coming from a loaded project
      // so we don't have to re-evaluate defaults
      // this is due to the current implementation of the color scale
      const patchedOptions = { ...project.visualOptions }
      Object.keys(patchedOptions).forEach((k) => {
        if (isPlainObject(patchedOptions[k])) {
          patchedOptions[k].__loaded = true
        }
      })
      setVisualOptions(project.visualOptions)
    },
    [hydrateFromSavedProject]
  )

  //setting initial chart and related options
  useEffect(() => {
    setCurrentChart(charts[0])
    const options = getOptionsConfig(charts[0]?.visualOptions)
    setVisualOptions(getDefaultOptionsValues(options))
  }, [])

  const subDescription = ['소스를 직접 붙여 넣기 하시거나 파일 업로드를 통해 데이터 입력이 가능합니다. 입력 후 자동으로 차트 선택 페이지로 이동합니다', '데이터 옵션을 변경하실 수 있습니다. 데이터를 확인하신 후 원하시는 차트를 선택 하셔서 ‘다음’ 버튼을 선택해 주세요.'
, '‘치수’를 Drag & Drop하여 ‘차트 변수’에 배치하신 후 ‘다음‘버튼을 선택해 주세요. 차트 변수가 정확하지 않을 경우 시각화 파일이 노출되지 않을 수 있습니다.', '시각화 옵션을 변경하실 수 있습니다.  완료 후 ‘다운로드’ 버튼을 선택 하여 파일을 다운로드 받으세요. 데이터 활용 커뮤니티로 공유도 가능합니다.']

  return (
    <nav>
      {/* <Header menuItems={HeaderItems} /> */}
      <div className="sub-content">
        <main className="bdm-content">
          {/* <SubHeader /> */}
          <div className="content-inner">
            <div className="data-tab-wrap">
              <ul className="data-tab col4">
                <li className={stepCnt === '1' ? 'on' : ''}>
                  <i>아이콘</i>
                  <span>step 1. 데이터 선택</span>
                </li>
                <li className={stepCnt === '2' ? 'on' : ''}>
                  <i>아이콘</i>
                  <span>step 2. 차트 선택</span>
                </li>
                <li className={stepCnt === '3' ? 'on' : ''}>
                  <i>아이콘</i>
                  <span>step 3. 옵션 선택</span>
                </li>
                <li className={stepCnt === '4' ? 'on' : ''}>
                  <i>아이콘</i>
                  <span>step 4. 시각화 만들기</span>
                </li>
              </ul>
              <p>{subDescription[stepCnt-1]}</p>
            </div>
            {(data && currentChart ? mappingLoading : loading) && (
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  width: '2rem',
                  height: '2rem',
                  borderWidth: '2px',
                  marginLeft: '2rem',
                }}
              />
            )}
            <DataLoader {...dataLoader} hydrateFromProject={importProject} setStepCnt={setStepCnt} stepCnt={stepCnt} />
            {data && stepCnt === '2' && (
              <ChartSelector
                availableCharts={charts}
                currentChart={currentChart}
                setCurrentChart={handleChartChange}
                setStepCnt={setStepCnt}
                {...dataLoader}
              />
            )}
            {data && currentChart && stepCnt === '3' && (
              <DataMapping
                ref={dataMappingRef}
                dimensions={currentChart.dimensions}
                dataTypes={data.dataTypes}
                mapping={mapping}
                setMapping={setMapping}
                setStepCnt={setStepCnt}
              />
            )}
            {data && currentChart && stepCnt === '4'  && (
              <ChartPreviewWithOptions
                chart={currentChart}
                dataset={data.dataset}
                dataTypes={data.dataTypes}
                mapping={mapping}
                visualOptions={visualOptions}
                setVisualOptions={setVisualOptions}
                setRawViz={setRawViz}
                setMappingLoading={setMappingLoading}
                rawViz={rawViz}
                exportProject={exportProject}
                setStepCnt={setStepCnt}
              />
            )}
          </div>
        </main>
      </div>
    </nav>
  )
}

export default App

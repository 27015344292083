import React, { useCallback, useState } from 'react'

export default function StackSelector({
  title,
  value,
  list,
  onChange,
  ...props
}) {
  const [dropClass, setDropClass] = useState('')
  const handleChange = useCallback(
    (nextDimension) => {
      if (onChange) {
        onChange(nextDimension)
      }
      setDropClass('')
    },
    [onChange]
  )

  return (
    <div className="option">
      {title}
      <div className="dropdown-wrap">
        {list.length !== 0 && (
          <div
            className={['dropdown', dropClass].join(' ')}
            onClick={() => {
              dropClass ? setDropClass('') : setDropClass('on')
            }}
          >
            {value ? value : '열'}
            <i className="down"></i>
          </div>
        )}

        <div className={['dropdown-menu', dropClass].join(' ')}>
          {value && <div onClick={() => handleChange(null)}>{'Do not stack'}</div>}
          {Object.keys(list).map((key) => {
            return (
              <div key={key} onClick={() => handleChange(key)}>
                {key}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

import isObject from 'lodash/isObject'
import React from 'react'

const ChartOptionSelect = ({
  options = [],
  value,
  error,
  onChange,
  default: defaultValue,
  label,
  ...props
}) => {
  return (
    <div className={props.className}>
      {label}
      <label className="bg">
        <select
          className="custom-select raw-select"
          value={value ?? defaultValue}
          onChange={(e) => {
            const stringValue = e.target.value
            const value =
              props.type === 'number' ? Number(stringValue) : stringValue
            onChange(value)
          }}
        >
          {options.map((option) =>
            isObject(option) ? (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ) : (
              <option key={option} value={option}>
                {option}
              </option>
            )
          )}
        </select>
        {error && (
          <small>
            <i>{error}</i>
          </small>
        )}
      </label>
    </div>
  )
}

export default React.memo(ChartOptionSelect)

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadFile_dropzone__vW4WH {\n  height: 432px;\n  border: 1px dashed var(--gray-300);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center; }\n\n.UploadFile_dropzone__vW4WH.UploadFile_reject__1Vw1x {\n  border: 2px dashed var(--danger); }\n\n.UploadFile_dropzone__vW4WH.UploadFile_accept__mPIC2 {\n  border: 2px dashed var(--success); }\n\n.UploadFile_browse-button__ixVrC {\n  font-size: 13px;\n  line-height: 16px;\n  text-align: center;\n  color: #ffffff;\n  height: 28px;\n  width: 80px;\n  margin: 0 10px; }\n", ""]);
// Exports
exports.locals = {
	"dropzone": "UploadFile_dropzone__vW4WH",
	"reject": "UploadFile_reject__1Vw1x",
	"accept": "UploadFile_accept__mPIC2",
	"browse-button": "UploadFile_browse-button__ixVrC"
};
module.exports = exports;

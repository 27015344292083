import uniq from 'lodash/uniq'
import React, { useCallback, useMemo, useState } from 'react'
import styles from './ChartSelector.module.scss'

function filterCharts(charts, filter) {
  return filter === 'All charts'
    ? charts
    : charts.filter((d) => d.metadata.categories.indexOf(filter) !== -1)
}

function ChartSelector({ availableCharts, currentChart, setCurrentChart, setStepCnt, setUserInput }) {
  const [filter, setFilter] = useState('All charts')

  const charts = useMemo(() => {
    return filterCharts(availableCharts, filter)
  }, [availableCharts, filter])

  const handleFilterChange = useCallback(
    (nextFilter) => {
      setFilter(nextFilter)
      const nextCharts = filterCharts(availableCharts, nextFilter)
      if (nextCharts.indexOf(currentChart) === -1) {
        setCurrentChart(nextCharts[0])
      }
    },
    [availableCharts, currentChart, setCurrentChart]
  )

  const [dropClass, setDropClass] = useState('')

  return (
    <div className="data-content-wrap chart">
      <div className="data-nav">
        <p className="title">차트선택</p>
        {currentChart && (
          <div className={[styles.currentChart, "chart-detail on"].join(' ')}>
            <img variant="top" src={currentChart.metadata.thumbnail} alt="thumbnail"/>
            <div className="textWrap">
              <p className="title">{currentChart.metadata.name}</p>
              <p className="text">{currentChart.metadata.description}</p>
            </div>
          </div>
        )}
        <div className="btnNew-wrap">
          <div className="btnNew grey prev" onClick={()=> {
            setStepCnt('1')
            setUserInput('')
          }}>이전</div>
          <div className="btnNew grey next" onClick={()=>setStepCnt('3')}>다음</div>
        </div>
      </div>
      <div className="data-content">
        <div className="chart-dropdown">
          <p>차트 분류</p>
          <div className="dropdown-wrap">
            <div
              className={['dropdown', dropClass].join(' ')}
              onClick={() => {
                dropClass ? setDropClass('') : setDropClass('on')
              }}
            >
              {filter.charAt(0).toUpperCase() + filter.slice(1)}
              <i className="down"></i>
            </div>
            <div className={['dropdown-menu', dropClass].join(' ')}>
               <div key={'All charts'} onClick={() => handleFilterChange('All charts')}>모든 차트</div>
               {uniq(
                 availableCharts.map((d) => d.metadata.categories).flat()
               ).map((d) => {
                 return (
                   <div key={d} onClick={() => handleFilterChange(d)}>
                     {d.charAt(0).toUpperCase() + d.slice(1)}
                   </div>
                 )
               })}
             </div>
          </div>
        </div>
        <ul>
        {charts.map((d, i) => {
          return (
            <li key={'chart-' + i} onClick={() => {
                setCurrentChart(d)
              }}
              className={d === currentChart ? 'on' : ''}
              >
                <img src={d.metadata.icon} alt="icon"></img>
              <div className="textWrap">
                <p>{d.metadata.name}</p>
                <span>{d.metadata.categories
                      .join(', ')
                      .charAt(0)
                      .toUpperCase() +
                      d.metadata.categories.join(', ').slice(1)}</span>
              </div>
            </li>
          )
        })}
        </ul>
      </div>
    </div>
  )
}

export default React.memo(ChartSelector)
